var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "vx-card",
    { attrs: { title: "Color", "code-toggler": "" } },
    [
      _c("p", [
        _vm._v("You can change the Avatar's color by using the property "),
        _c("code", [_vm._v("color")]),
        _vm._v(
          ". If needed you could also change the text's color with the property "
        ),
        _c("code", [_vm._v("text-color")]),
      ]),
      _c(
        "vs-alert",
        {
          staticClass: "mt-5",
          attrs: { color: "primary", icon: "new_releases", active: "true" },
        },
        [
          _c("span", [
            _vm._v(
              "If the text has more than 5 letters, only the first letter will be shown as in the second avatar, the name is "
            ),
            _c("code", [_vm._v("Luis Daniel")]),
            _vm._v(
              " and only the letters LD will be shown if the word contains spaces the initial of each one is added."
            ),
          ]),
        ]
      ),
      _c(
        "div",
        { staticClass: "demo-alignment mt-5" },
        [
          _c("vs-avatar", { attrs: { color: "primary", text: "primary" } }),
          _c("vs-avatar", { attrs: { color: "success", text: "success" } }),
          _c("vs-avatar", { attrs: { color: "danger", text: "danger" } }),
          _c("vs-avatar", { attrs: { color: "warning", text: "warning" } }),
          _c("vs-avatar", { attrs: { color: "dark", text: "dark" } }),
          _c("vs-avatar", {
            attrs: { color: "rgb(200, 21, 129)", text: "RGB" },
          }),
          _c("vs-avatar", { attrs: { color: "#18cd5b", text: "HEX" } }),
          _c("vs-avatar", {
            attrs: {
              color: "#26302a",
              "text-color": "rgb(246, 190, 16)",
              text: "HEX RGB",
            },
          }),
        ],
        1
      ),
      _c("template", { slot: "codeContainer" }, [
        _vm._v(
          '\n<vs-avatar color="primary" text="primary"/>\n<vs-avatar color="success" text="success"/>\n<vs-avatar color="danger" text="danger"/>\n<vs-avatar color="warning" text="warning"/>\n<vs-avatar color="dark" text="dark"/>\n<vs-avatar color="rgb(200, 21, 129)" text="RGB"/>\n<vs-avatar color="#18cd5b" text="HEX"/>\n<vs-avatar color="#26302a" text-color="rgb(246, 190, 16)" text="HEX RGB"/>\n        '
        ),
      ]),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }