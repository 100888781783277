var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "vx-card",
    { attrs: { title: "With Badge", "code-toggler": "" } },
    [
      _c("p", [
        _vm._v(
          "You can add a notification label to the avatar, either a reference point or a number with the property "
        ),
        _c("code", [_vm._v("badge")]),
      ]),
      _c(
        "p",
        [
          _vm._v(
            "You could also change the badge's color by using the property "
          ),
          _c("code", [_vm._v("badge-color")]),
          _vm._v(". You are able to use the "),
          _c("router-link", { attrs: { to: "/ui-elements/colors" } }, [
            _vm._v("Main Colors"),
          ]),
          _vm._v(" or "),
          _c("strong", [_vm._v("RGB")]),
          _vm._v(" and "),
          _c("strong", [_vm._v("HEX")]),
          _vm._v(" colors."),
        ],
        1
      ),
      _c(
        "vs-alert",
        {
          staticClass: "mt-5",
          attrs: { icon: "warning", active: "true", color: "warning" },
        },
        [
          _c("span", [
            _vm._v("Only "),
            _c("strong", [_vm._v("RGB")]),
            _vm._v(" and "),
            _c("strong", [_vm._v("HEX")]),
            _vm._v(" colors are supported."),
          ]),
        ]
      ),
      _c(
        "div",
        { staticClass: "demo-alignment mt-5" },
        [
          _c("vs-avatar", { attrs: { badge: "" } }),
          _c("vs-avatar", { attrs: { badge: _vm.badge1, text: "Luisdaniel" } }),
          _c("vs-avatar", {
            attrs: {
              "badge-color": "rgb(140, 23, 164)",
              badge: _vm.badge2,
              text: "Luisd",
            },
          }),
          _c(
            "vs-button",
            {
              attrs: { color: "primary", type: "filled" },
              on: { click: _vm.increment },
            },
            [_vm._v(" Increment Badge counter ")]
          ),
        ],
        1
      ),
      _c("template", { slot: "codeContainer" }, [
        _vm._v(
          '\n<template>\n  <vs-avatar badge />\n  <vs-avatar :badge="badge1" text="Luisdaniel" />\n  <vs-avatar badge-color="rgb(140, 23, 164)" :badge="badge2" text="Luisd" />\n  <vs-button color="primary" type="filled" @click="increment"> Increment Badge counter </vs-button>\n</template>\n\n<script>\nexport default {\n  data: () => ({\n    badge1: 2,\n    badge2: 10,\n  }),\n  methods: {\n    increment() {\n      this.badge1++\n      this.badge2++\n    }\n  }\n}\n</script>\n        '
        ),
      ]),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }