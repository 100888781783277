var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "vx-card",
    { attrs: { title: "size", "code-toggler": "" } },
    [
      _c("p", [
        _vm._v(
          "You can change the size of the Avatar with the property size the allowed values are:"
        ),
      ]),
      _c("vx-list", { attrs: { list: ["large", "small", "Custom measures"] } }),
      _c(
        "vs-alert",
        {
          staticClass: "mb-1",
          attrs: { color: "primary", icon: "new_releases", active: "true" },
        },
        [
          _c("span", [
            _vm._v("In the example the last avatar has the custom size of "),
            _c("code", [_vm._v("70px")]),
            _vm._v(" the added value will be the avatar's "),
            _c("strong", [_vm._v("height")]),
            _vm._v(" and "),
            _c("strong", [_vm._v("width")]),
          ]),
        ]
      ),
      _c(
        "div",
        { staticClass: "demo-alignment mt-5" },
        [
          _c("vs-avatar", { attrs: { size: "small" } }),
          _c("vs-avatar", { attrs: { text: "Luis Daniel" } }),
          _c("vs-avatar", {
            attrs: { size: "large", src: "https://i.imgur.com/ezM6SJ5.png" },
          }),
          _c("vs-avatar", {
            attrs: { size: "70px", src: "https://i.imgur.com/ezM6SJ5.png" },
          }),
        ],
        1
      ),
      _c("template", { slot: "codeContainer" }, [
        _vm._v(
          '\n<vs-avatar size="small" />\n<vs-avatar text="Luis Daniel"/>\n<vs-avatar size="large" src="https://i.imgur.com/ezM6SJ5.png"/>\n<vs-avatar size="70px" src="https://i.imgur.com/ezM6SJ5.png"/>\n        '
        ),
      ]),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }