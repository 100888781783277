var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { attrs: { id: "avatar-demo" } },
    [
      _c("avatar-default"),
      _c("avatar-size"),
      _c("avatar-color"),
      _c("avatar-with-badge"),
      _c("avatar-icon"),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }