var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "vx-card",
    { attrs: { title: "Default", "code-toggler": "" } },
    [
      _c("p", [
        _vm._v(
          "Often you need to add an user's avatar to your app. In order to make this we have the component "
        ),
        _c("code", [_vm._v("vs-avatar")]),
      ]),
      _c("p", [
        _vm._v("To add an internal text value such as "),
        _c("code", [_vm._v("Luis")]),
        _vm._v(" use the "),
        _c("code", [_vm._v("text")]),
        _vm._v(" property"),
      ]),
      _c(
        "vs-alert",
        {
          staticClass: "mt-5",
          attrs: { color: "primary", icon: "new_releases", active: "true" },
        },
        [
          _c("span", [
            _vm._v(
              "If the text has more than 5 letters, only the first letter will be shown as in the second avatar, the name is "
            ),
            _c("code", [_vm._v("Luis Daniel")]),
            _vm._v(
              " and only the letters LD will be shown if the word contains spaces the initial of each one is added."
            ),
          ]),
        ]
      ),
      _c(
        "div",
        { staticClass: "demo-alignment mt-5" },
        [
          _c("vs-avatar"),
          _c("vs-avatar", { attrs: { text: "Luis Daniel" } }),
          _c("vs-avatar", { attrs: { text: "Luisd" } }),
          _c("vs-avatar", {
            attrs: { src: "https://i.imgur.com/ezM6SJ5.png" },
          }),
        ],
        1
      ),
      _c("template", { slot: "codeContainer" }, [
        _vm._v(
          '\n<vs-avatar />\n<vs-avatar text="Luis Daniel"/>\n<vs-avatar text="Luisd"/>\n<vs-avatar src="https://i.imgur.com/ezM6SJ5.png"/>\n        '
        ),
      ]),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }