var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "vx-card",
    { attrs: { title: "Icon", "code-toggler": "" } },
    [
      _c("p", [
        _vm._v("You can change the main icon of the Avatar with the property "),
        _c("code", [_vm._v("icon")]),
      ]),
      _c(
        "vs-alert",
        {
          staticClass: "mt-5",
          attrs: { color: "primary", icon: "new_releases", active: "true" },
        },
        [
          _c("p", [
            _vm._v("Vuesax uses the "),
            _c("strong", [_vm._v("Google Material Icons")]),
            _vm._v(
              " font library by default. For a list of all available icons, visit the official "
            ),
            _c(
              "a",
              {
                attrs: { href: "https://material.io/icons/", target: "_blank" },
              },
              [_vm._v("Material Icons page")]
            ),
            _vm._v(
              ". Other icon libraries can be used by providing the class for the respective pack in the icon-pack property. ex. FA4 uses fa or fas, FA5 uses fas, far, or fal."
            ),
          ]),
          _c("p", [
            _vm._v("This icon is only displayed when there is no property as "),
            _c("code", [_vm._v("text")]),
            _vm._v(" or "),
            _c("code", [_vm._v("src")]),
          ]),
        ]
      ),
      _c(
        "div",
        { staticClass: "demo-alignment mt-5" },
        [
          _c("vs-avatar", {
            attrs: { "icon-pack": "feather", icon: "icon-user" },
          }),
          _c("vs-avatar", {
            attrs: {
              color: "primary",
              "icon-pack": "feather",
              icon: "icon-calendar",
            },
          }),
          _c("vs-avatar", {
            attrs: {
              color: "success",
              "icon-pack": "feather",
              icon: "icon-inbox",
            },
          }),
          _c("vs-avatar", {
            attrs: {
              color: "danger",
              "icon-pack": "feather",
              icon: "icon-camera",
            },
          }),
          _c("vs-avatar", {
            attrs: {
              color: "warning",
              "icon-pack": "feather",
              icon: "icon-award",
            },
          }),
          _c("vs-avatar", {
            attrs: { color: "dark", "icon-pack": "feather", icon: "icon-code" },
          }),
        ],
        1
      ),
      _c("template", { slot: "codeContainer" }, [
        _vm._v(
          '\n<vs-avatar icon-pack="feather" icon="icon-user" />\n\n<vs-avatar color="primary" icon-pack="feather" icon="icon-calendar" />\n\n<vs-avatar color="success" icon-pack="feather" icon="icon-inbox" />\n\n<vs-avatar color="danger" icon-pack="feather" icon="icon-camera" />\n\n<vs-avatar color="warning" icon-pack="feather" icon="icon-award" />\n\n<vs-avatar color="dark" icon-pack="feather" icon="icon-code" />\n        '
        ),
      ]),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }